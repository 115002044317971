import { default as config_account0fAWR0HELaMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/accounts/config_account.js?macro=true";
import { default as indexuaLbXMusYLMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/accounts/index.vue?macro=true";
import { default as control_45centerXGju0ncCd1Meta } from "/home/les/work/EMN/etmf_api/frontend/pages/admin/control-center.vue?macro=true";
import { default as confirm_45forgot_45passs9pQT3sB1PMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/confirm-forgot-pass.vue?macro=true";
import { default as config_logiYdqIJDqW6Meta } from "/home/les/work/EMN/etmf_api/frontend/pages/dashboard/config_log.js?macro=true";
import { default as indexIhnrKh3JxEMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/dashboard/index.vue?macro=true";
import { default as config_data_dictionaryV1LD2H06DhMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/dd/config_data_dictionary.js?macro=true";
import { default as index56zKgfrKQYMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/dd/index.vue?macro=true";
import { default as config_accountCMA19q4Lw9Meta } from "/home/les/work/EMN/etmf_api/frontend/pages/example/config_account.js?macro=true";
import { default as index65Lk9HMhUVMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/example/index.vue?macro=true";
import { default as config_accountTJbhrBIpyXMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/example2/config_account.js?macro=true";
import { default as indexbbkzYxHBACMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/example2/index.vue?macro=true";
import { default as _91_91node_id_93_937LdMYkWkYzMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/explorer/[[node_id]].vue?macro=true";
import { default as dataDTcpeoeUnPMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/explorer/data.js?macro=true";
import { default as config_treatmentHunvA27G6gMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/ip/config_treatment.js?macro=true";
import { default as indexSrgtd6WIAUMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/ip/index.vue?macro=true";
import { default as loginieeQwUyItrMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/login.vue?macro=true";
import { default as indexB8VcF059yoMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/metric/index.vue?macro=true";
import { default as indexpSwjKEoMQ2Meta } from "/home/les/work/EMN/etmf_api/frontend/pages/milestone_setup/index.vue?macro=true";
import { default as config_organizationgREPqyteqgMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/organizations/config_organization.js?macro=true";
import { default as indexmMBOnyMJmdMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/organizations/index.vue?macro=true";
import { default as configxozFNfjCM5Meta } from "/home/les/work/EMN/etmf_api/frontend/pages/pending/config.js?macro=true";
import { default as data1cO2L3V7RlMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/pending/data.js?macro=true";
import { default as indexygS4bg5IV2Meta } from "/home/les/work/EMN/etmf_api/frontend/pages/pending/index.vue?macro=true";
import { default as indexd7HTpRXqyxMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/permission/index.vue?macro=true";
import { default as config_personnelym6jYFfS0ZMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/personnel/config_personnel.js?macro=true";
import { default as index2kbaFLgHsQMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/personnel/index.vue?macro=true";
import { default as indexuktBK1kZIaMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/profile/index.vue?macro=true";
import { default as config_protocol_has_account_has_organizationfo6HuY5DAgMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_account_has_organization/config_protocol_has_account_has_organization.js?macro=true";
import { default as indexBJNG3ZligAMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_account_has_organization/index.vue?macro=true";
import { default as config_protocol_has_accountJCGCsVZcFEMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_account/config_protocol_has_account.js?macro=true";
import { default as indexfcD2KOgZ2gMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_account/index.vue?macro=true";
import { default as config_protocol_has_organizationqeJWlNQnigMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_organization/config_protocol_has_organization.js?macro=true";
import { default as index9iBMcB1G0DMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_organization/index.vue?macro=true";
import { default as config_protocol_has_personnelAXsvpd0fmcMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_personnel/config_protocol_has_personnel.js?macro=true";
import { default as indexzLDhA16nhaMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_personnel/index.vue?macro=true";
import { default as index45kXsTU3R3Meta } from "/home/les/work/EMN/etmf_api/frontend/pages/protocols/index.vue?macro=true";
import { default as dataZhtIr0fKhaMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/report/data.js?macro=true";
import { default as indexjLbpAVxY0oMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/report/index.vue?macro=true";
import { default as reset_45passwords6rYBIHE0CMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/reset-password.vue?macro=true";
import { default as config_sitecAexz8C1VTMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/site/config_site.js?macro=true";
import { default as indexqlgcCUcQWFMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/site/index.vue?macro=true";
import { default as config_study_roleoyQX3NUCaWMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/study_role/config_study_role.js?macro=true";
import { default as index9F3GGo6OBjMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/study_role/index.vue?macro=true";
import { default as config_taskMcm469qn1wMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/task/config_task.js?macro=true";
import { default as index7K3HNOeTJ2Meta } from "/home/les/work/EMN/etmf_api/frontend/pages/task/index.vue?macro=true";
import { default as index6lN3LNwXVjMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/testflex/index.vue?macro=true";
import { default as indext1wOxqX8laMeta } from "/home/les/work/EMN/etmf_api/frontend/pages/tree_permission/index.vue?macro=true";
export default [
  {
    name: config_account0fAWR0HELaMeta?.name ?? "accounts-config_account",
    path: config_account0fAWR0HELaMeta?.path ?? "/accounts/config_account",
    meta: config_account0fAWR0HELaMeta || {},
    alias: config_account0fAWR0HELaMeta?.alias || [],
    redirect: config_account0fAWR0HELaMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/accounts/config_account.js").then(m => m.default || m)
  },
  {
    name: indexuaLbXMusYLMeta?.name ?? "accounts",
    path: indexuaLbXMusYLMeta?.path ?? "/accounts",
    meta: indexuaLbXMusYLMeta || {},
    alias: indexuaLbXMusYLMeta?.alias || [],
    redirect: indexuaLbXMusYLMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: control_45centerXGju0ncCd1Meta?.name ?? "admin-control-center",
    path: control_45centerXGju0ncCd1Meta?.path ?? "/admin/control-center",
    meta: control_45centerXGju0ncCd1Meta || {},
    alias: control_45centerXGju0ncCd1Meta?.alias || [],
    redirect: control_45centerXGju0ncCd1Meta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/admin/control-center.vue").then(m => m.default || m)
  },
  {
    name: confirm_45forgot_45passs9pQT3sB1PMeta?.name ?? "confirm-forgot-pass",
    path: confirm_45forgot_45passs9pQT3sB1PMeta?.path ?? "/confirm-forgot-pass",
    meta: confirm_45forgot_45passs9pQT3sB1PMeta || {},
    alias: confirm_45forgot_45passs9pQT3sB1PMeta?.alias || [],
    redirect: confirm_45forgot_45passs9pQT3sB1PMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/confirm-forgot-pass.vue").then(m => m.default || m)
  },
  {
    name: config_logiYdqIJDqW6Meta?.name ?? "dashboard-config_log",
    path: config_logiYdqIJDqW6Meta?.path ?? "/dashboard/config_log",
    meta: config_logiYdqIJDqW6Meta || {},
    alias: config_logiYdqIJDqW6Meta?.alias || [],
    redirect: config_logiYdqIJDqW6Meta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/dashboard/config_log.js").then(m => m.default || m)
  },
  {
    name: indexIhnrKh3JxEMeta?.name ?? "dashboard",
    path: indexIhnrKh3JxEMeta?.path ?? "/dashboard",
    meta: indexIhnrKh3JxEMeta || {},
    alias: indexIhnrKh3JxEMeta?.alias || [],
    redirect: indexIhnrKh3JxEMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: config_data_dictionaryV1LD2H06DhMeta?.name ?? "dd-config_data_dictionary",
    path: config_data_dictionaryV1LD2H06DhMeta?.path ?? "/dd/config_data_dictionary",
    meta: config_data_dictionaryV1LD2H06DhMeta || {},
    alias: config_data_dictionaryV1LD2H06DhMeta?.alias || [],
    redirect: config_data_dictionaryV1LD2H06DhMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/dd/config_data_dictionary.js").then(m => m.default || m)
  },
  {
    name: index56zKgfrKQYMeta?.name ?? "dd",
    path: index56zKgfrKQYMeta?.path ?? "/dd",
    meta: index56zKgfrKQYMeta || {},
    alias: index56zKgfrKQYMeta?.alias || [],
    redirect: index56zKgfrKQYMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/dd/index.vue").then(m => m.default || m)
  },
  {
    name: config_accountCMA19q4Lw9Meta?.name ?? "example-config_account",
    path: config_accountCMA19q4Lw9Meta?.path ?? "/example/config_account",
    meta: config_accountCMA19q4Lw9Meta || {},
    alias: config_accountCMA19q4Lw9Meta?.alias || [],
    redirect: config_accountCMA19q4Lw9Meta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/example/config_account.js").then(m => m.default || m)
  },
  {
    name: index65Lk9HMhUVMeta?.name ?? "example",
    path: index65Lk9HMhUVMeta?.path ?? "/example",
    meta: index65Lk9HMhUVMeta || {},
    alias: index65Lk9HMhUVMeta?.alias || [],
    redirect: index65Lk9HMhUVMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/example/index.vue").then(m => m.default || m)
  },
  {
    name: config_accountTJbhrBIpyXMeta?.name ?? "example2-config_account",
    path: config_accountTJbhrBIpyXMeta?.path ?? "/example2/config_account",
    meta: config_accountTJbhrBIpyXMeta || {},
    alias: config_accountTJbhrBIpyXMeta?.alias || [],
    redirect: config_accountTJbhrBIpyXMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/example2/config_account.js").then(m => m.default || m)
  },
  {
    name: indexbbkzYxHBACMeta?.name ?? "example2",
    path: indexbbkzYxHBACMeta?.path ?? "/example2",
    meta: indexbbkzYxHBACMeta || {},
    alias: indexbbkzYxHBACMeta?.alias || [],
    redirect: indexbbkzYxHBACMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/example2/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91node_id_93_937LdMYkWkYzMeta?.name ?? "explorer-node_id",
    path: _91_91node_id_93_937LdMYkWkYzMeta?.path ?? "/explorer/:node_id?",
    meta: _91_91node_id_93_937LdMYkWkYzMeta || {},
    alias: _91_91node_id_93_937LdMYkWkYzMeta?.alias || [],
    redirect: _91_91node_id_93_937LdMYkWkYzMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/explorer/[[node_id]].vue").then(m => m.default || m)
  },
  {
    name: dataDTcpeoeUnPMeta?.name ?? "explorer-data",
    path: dataDTcpeoeUnPMeta?.path ?? "/explorer/data",
    meta: dataDTcpeoeUnPMeta || {},
    alias: dataDTcpeoeUnPMeta?.alias || [],
    redirect: dataDTcpeoeUnPMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/explorer/data.js").then(m => m.default || m)
  },
  {
    name: config_treatmentHunvA27G6gMeta?.name ?? "ip-config_treatment",
    path: config_treatmentHunvA27G6gMeta?.path ?? "/ip/config_treatment",
    meta: config_treatmentHunvA27G6gMeta || {},
    alias: config_treatmentHunvA27G6gMeta?.alias || [],
    redirect: config_treatmentHunvA27G6gMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/ip/config_treatment.js").then(m => m.default || m)
  },
  {
    name: indexSrgtd6WIAUMeta?.name ?? "ip",
    path: indexSrgtd6WIAUMeta?.path ?? "/ip",
    meta: indexSrgtd6WIAUMeta || {},
    alias: indexSrgtd6WIAUMeta?.alias || [],
    redirect: indexSrgtd6WIAUMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/ip/index.vue").then(m => m.default || m)
  },
  {
    name: loginieeQwUyItrMeta?.name ?? "login",
    path: loginieeQwUyItrMeta?.path ?? "/login",
    meta: loginieeQwUyItrMeta || {},
    alias: loginieeQwUyItrMeta?.alias || [],
    redirect: loginieeQwUyItrMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexB8VcF059yoMeta?.name ?? "metric",
    path: indexB8VcF059yoMeta?.path ?? "/metric",
    meta: indexB8VcF059yoMeta || {},
    alias: indexB8VcF059yoMeta?.alias || [],
    redirect: indexB8VcF059yoMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/metric/index.vue").then(m => m.default || m)
  },
  {
    name: indexpSwjKEoMQ2Meta?.name ?? "milestone_setup",
    path: indexpSwjKEoMQ2Meta?.path ?? "/milestone_setup",
    meta: indexpSwjKEoMQ2Meta || {},
    alias: indexpSwjKEoMQ2Meta?.alias || [],
    redirect: indexpSwjKEoMQ2Meta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/milestone_setup/index.vue").then(m => m.default || m)
  },
  {
    name: config_organizationgREPqyteqgMeta?.name ?? "organizations-config_organization",
    path: config_organizationgREPqyteqgMeta?.path ?? "/organizations/config_organization",
    meta: config_organizationgREPqyteqgMeta || {},
    alias: config_organizationgREPqyteqgMeta?.alias || [],
    redirect: config_organizationgREPqyteqgMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/organizations/config_organization.js").then(m => m.default || m)
  },
  {
    name: indexmMBOnyMJmdMeta?.name ?? "organizations",
    path: indexmMBOnyMJmdMeta?.path ?? "/organizations",
    meta: indexmMBOnyMJmdMeta || {},
    alias: indexmMBOnyMJmdMeta?.alias || [],
    redirect: indexmMBOnyMJmdMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: configxozFNfjCM5Meta?.name ?? "pending-config",
    path: configxozFNfjCM5Meta?.path ?? "/pending/config",
    meta: configxozFNfjCM5Meta || {},
    alias: configxozFNfjCM5Meta?.alias || [],
    redirect: configxozFNfjCM5Meta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/pending/config.js").then(m => m.default || m)
  },
  {
    name: data1cO2L3V7RlMeta?.name ?? "pending-data",
    path: data1cO2L3V7RlMeta?.path ?? "/pending/data",
    meta: data1cO2L3V7RlMeta || {},
    alias: data1cO2L3V7RlMeta?.alias || [],
    redirect: data1cO2L3V7RlMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/pending/data.js").then(m => m.default || m)
  },
  {
    name: indexygS4bg5IV2Meta?.name ?? "pending",
    path: indexygS4bg5IV2Meta?.path ?? "/pending",
    meta: indexygS4bg5IV2Meta || {},
    alias: indexygS4bg5IV2Meta?.alias || [],
    redirect: indexygS4bg5IV2Meta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/pending/index.vue").then(m => m.default || m)
  },
  {
    name: indexd7HTpRXqyxMeta?.name ?? "permission",
    path: indexd7HTpRXqyxMeta?.path ?? "/permission",
    meta: indexd7HTpRXqyxMeta || {},
    alias: indexd7HTpRXqyxMeta?.alias || [],
    redirect: indexd7HTpRXqyxMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/permission/index.vue").then(m => m.default || m)
  },
  {
    name: config_personnelym6jYFfS0ZMeta?.name ?? "personnel-config_personnel",
    path: config_personnelym6jYFfS0ZMeta?.path ?? "/personnel/config_personnel",
    meta: config_personnelym6jYFfS0ZMeta || {},
    alias: config_personnelym6jYFfS0ZMeta?.alias || [],
    redirect: config_personnelym6jYFfS0ZMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/personnel/config_personnel.js").then(m => m.default || m)
  },
  {
    name: index2kbaFLgHsQMeta?.name ?? "personnel",
    path: index2kbaFLgHsQMeta?.path ?? "/personnel",
    meta: index2kbaFLgHsQMeta || {},
    alias: index2kbaFLgHsQMeta?.alias || [],
    redirect: index2kbaFLgHsQMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/personnel/index.vue").then(m => m.default || m)
  },
  {
    name: indexuktBK1kZIaMeta?.name ?? "profile",
    path: indexuktBK1kZIaMeta?.path ?? "/profile",
    meta: indexuktBK1kZIaMeta || {},
    alias: indexuktBK1kZIaMeta?.alias || [],
    redirect: indexuktBK1kZIaMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_account_has_organizationfo6HuY5DAgMeta?.name ?? "protocol_has_account_has_organization-config_protocol_has_account_has_organization",
    path: config_protocol_has_account_has_organizationfo6HuY5DAgMeta?.path ?? "/protocol_has_account_has_organization/config_protocol_has_account_has_organization",
    meta: config_protocol_has_account_has_organizationfo6HuY5DAgMeta || {},
    alias: config_protocol_has_account_has_organizationfo6HuY5DAgMeta?.alias || [],
    redirect: config_protocol_has_account_has_organizationfo6HuY5DAgMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_account_has_organization/config_protocol_has_account_has_organization.js").then(m => m.default || m)
  },
  {
    name: indexBJNG3ZligAMeta?.name ?? "protocol_has_account_has_organization",
    path: indexBJNG3ZligAMeta?.path ?? "/protocol_has_account_has_organization",
    meta: indexBJNG3ZligAMeta || {},
    alias: indexBJNG3ZligAMeta?.alias || [],
    redirect: indexBJNG3ZligAMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_account_has_organization/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_accountJCGCsVZcFEMeta?.name ?? "protocol_has_account-config_protocol_has_account",
    path: config_protocol_has_accountJCGCsVZcFEMeta?.path ?? "/protocol_has_account/config_protocol_has_account",
    meta: config_protocol_has_accountJCGCsVZcFEMeta || {},
    alias: config_protocol_has_accountJCGCsVZcFEMeta?.alias || [],
    redirect: config_protocol_has_accountJCGCsVZcFEMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_account/config_protocol_has_account.js").then(m => m.default || m)
  },
  {
    name: indexfcD2KOgZ2gMeta?.name ?? "protocol_has_account",
    path: indexfcD2KOgZ2gMeta?.path ?? "/protocol_has_account",
    meta: indexfcD2KOgZ2gMeta || {},
    alias: indexfcD2KOgZ2gMeta?.alias || [],
    redirect: indexfcD2KOgZ2gMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_account/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_organizationqeJWlNQnigMeta?.name ?? "protocol_has_organization-config_protocol_has_organization",
    path: config_protocol_has_organizationqeJWlNQnigMeta?.path ?? "/protocol_has_organization/config_protocol_has_organization",
    meta: config_protocol_has_organizationqeJWlNQnigMeta || {},
    alias: config_protocol_has_organizationqeJWlNQnigMeta?.alias || [],
    redirect: config_protocol_has_organizationqeJWlNQnigMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_organization/config_protocol_has_organization.js").then(m => m.default || m)
  },
  {
    name: index9iBMcB1G0DMeta?.name ?? "protocol_has_organization",
    path: index9iBMcB1G0DMeta?.path ?? "/protocol_has_organization",
    meta: index9iBMcB1G0DMeta || {},
    alias: index9iBMcB1G0DMeta?.alias || [],
    redirect: index9iBMcB1G0DMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_organization/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_personnelAXsvpd0fmcMeta?.name ?? "protocol_has_personnel-config_protocol_has_personnel",
    path: config_protocol_has_personnelAXsvpd0fmcMeta?.path ?? "/protocol_has_personnel/config_protocol_has_personnel",
    meta: config_protocol_has_personnelAXsvpd0fmcMeta || {},
    alias: config_protocol_has_personnelAXsvpd0fmcMeta?.alias || [],
    redirect: config_protocol_has_personnelAXsvpd0fmcMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_personnel/config_protocol_has_personnel.js").then(m => m.default || m)
  },
  {
    name: indexzLDhA16nhaMeta?.name ?? "protocol_has_personnel",
    path: indexzLDhA16nhaMeta?.path ?? "/protocol_has_personnel",
    meta: indexzLDhA16nhaMeta || {},
    alias: indexzLDhA16nhaMeta?.alias || [],
    redirect: indexzLDhA16nhaMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/protocol_has_personnel/index.vue").then(m => m.default || m)
  },
  {
    name: index45kXsTU3R3Meta?.name ?? "protocols",
    path: index45kXsTU3R3Meta?.path ?? "/protocols",
    meta: index45kXsTU3R3Meta || {},
    alias: index45kXsTU3R3Meta?.alias || [],
    redirect: index45kXsTU3R3Meta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/protocols/index.vue").then(m => m.default || m)
  },
  {
    name: dataZhtIr0fKhaMeta?.name ?? "report-data",
    path: dataZhtIr0fKhaMeta?.path ?? "/report/data",
    meta: dataZhtIr0fKhaMeta || {},
    alias: dataZhtIr0fKhaMeta?.alias || [],
    redirect: dataZhtIr0fKhaMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/report/data.js").then(m => m.default || m)
  },
  {
    name: indexjLbpAVxY0oMeta?.name ?? "report",
    path: indexjLbpAVxY0oMeta?.path ?? "/report",
    meta: indexjLbpAVxY0oMeta || {},
    alias: indexjLbpAVxY0oMeta?.alias || [],
    redirect: indexjLbpAVxY0oMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/report/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwords6rYBIHE0CMeta?.name ?? "reset-password",
    path: reset_45passwords6rYBIHE0CMeta?.path ?? "/reset-password",
    meta: reset_45passwords6rYBIHE0CMeta || {},
    alias: reset_45passwords6rYBIHE0CMeta?.alias || [],
    redirect: reset_45passwords6rYBIHE0CMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: config_sitecAexz8C1VTMeta?.name ?? "site-config_site",
    path: config_sitecAexz8C1VTMeta?.path ?? "/site/config_site",
    meta: config_sitecAexz8C1VTMeta || {},
    alias: config_sitecAexz8C1VTMeta?.alias || [],
    redirect: config_sitecAexz8C1VTMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/site/config_site.js").then(m => m.default || m)
  },
  {
    name: indexqlgcCUcQWFMeta?.name ?? "site",
    path: indexqlgcCUcQWFMeta?.path ?? "/site",
    meta: indexqlgcCUcQWFMeta || {},
    alias: indexqlgcCUcQWFMeta?.alias || [],
    redirect: indexqlgcCUcQWFMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/site/index.vue").then(m => m.default || m)
  },
  {
    name: config_study_roleoyQX3NUCaWMeta?.name ?? "study_role-config_study_role",
    path: config_study_roleoyQX3NUCaWMeta?.path ?? "/study_role/config_study_role",
    meta: config_study_roleoyQX3NUCaWMeta || {},
    alias: config_study_roleoyQX3NUCaWMeta?.alias || [],
    redirect: config_study_roleoyQX3NUCaWMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/study_role/config_study_role.js").then(m => m.default || m)
  },
  {
    name: index9F3GGo6OBjMeta?.name ?? "study_role",
    path: index9F3GGo6OBjMeta?.path ?? "/study_role",
    meta: index9F3GGo6OBjMeta || {},
    alias: index9F3GGo6OBjMeta?.alias || [],
    redirect: index9F3GGo6OBjMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/study_role/index.vue").then(m => m.default || m)
  },
  {
    name: config_taskMcm469qn1wMeta?.name ?? "task-config_task",
    path: config_taskMcm469qn1wMeta?.path ?? "/task/config_task",
    meta: config_taskMcm469qn1wMeta || {},
    alias: config_taskMcm469qn1wMeta?.alias || [],
    redirect: config_taskMcm469qn1wMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/task/config_task.js").then(m => m.default || m)
  },
  {
    name: index7K3HNOeTJ2Meta?.name ?? "task",
    path: index7K3HNOeTJ2Meta?.path ?? "/task",
    meta: index7K3HNOeTJ2Meta || {},
    alias: index7K3HNOeTJ2Meta?.alias || [],
    redirect: index7K3HNOeTJ2Meta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/task/index.vue").then(m => m.default || m)
  },
  {
    name: index6lN3LNwXVjMeta?.name ?? "testflex",
    path: index6lN3LNwXVjMeta?.path ?? "/testflex",
    meta: index6lN3LNwXVjMeta || {},
    alias: index6lN3LNwXVjMeta?.alias || [],
    redirect: index6lN3LNwXVjMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/testflex/index.vue").then(m => m.default || m)
  },
  {
    name: indext1wOxqX8laMeta?.name ?? "tree_permission",
    path: indext1wOxqX8laMeta?.path ?? "/tree_permission",
    meta: indext1wOxqX8laMeta || {},
    alias: indext1wOxqX8laMeta?.alias || [],
    redirect: indext1wOxqX8laMeta?.redirect,
    component: () => import("/home/les/work/EMN/etmf_api/frontend/pages/tree_permission/index.vue").then(m => m.default || m)
  }
]